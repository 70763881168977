import React from 'react';
import TopicSectionVideo from './Section/TopicSectionVideo';
import TopicSectionBanner from './Section/TopicSectionBanner';

const TopicSection = () => (
  <div>
    <TopicSectionVideo />
    <TopicSectionBanner/>
  </div>
);



export default TopicSection;
