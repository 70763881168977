import React from "react";

import { PRIVACY_POLICY_DATA } from "../../Data";
import { CollapsibleSection } from "./CollapsibleSection";

const PrivatePolicyHeader = () => {
  return (
    <div
      className="relative bg-primary-color py-20 bg-cover bg-center"
      style={{
        backgroundImage: `url('https://equentis-web.s3.ap-south-1.amazonaws.com/RNR_old/bg_whocaninvest.jpg')`,
      }}
    >
      <div className="absolute inset-0 bg-primary-color opacity-70"></div>
      <div className="relative z-10 md:ml-40 mx-auto  flex justify-center  md:justify-start md:pt-0 pt-12 pb-0">
        <h1 className="text-2xl md:text-4xl font-bold text-white">
          Privacy Policy
        </h1>
      </div>
    </div>
  );
};

const PrivatePolicyContent = () => {
  return (
    <div className="mx-auto px-6 py-10  md:py-16 md:px-60 text-center font-pillGothic md:text-lg text-sm">
      <h2 className="text-3xl  text-primary-color mb-4">
        <strong>Privacy Policy</strong>
      </h2>

      <p className="mt-8  uppercase md:text-center text-left">
        We, Equentis Edutech Limited, with website id as
        https://www.equentisedutech.com and the creator of this Privacy Policy,
        affirm our commitment to safeguarding your privacy and protecting your
        information. To ensure the seamless provision of services, we may
        collect and disclose information about you. This notice elucidates our
        information practices and delineates the choices you have regarding the
        collection and utilization of your information.
      </p>

      <p className="mt-5 md:mt-8 ">
        <strong>Overview</strong>
      </p>

      <p className="mt-4 md:mt-8 text-gray-700 text-left">
        We are dedicated to upholding the privacy of your online data. We
        acknowledge the importance of providing suitable protection and
        administration for any personally identifiable information{" "}
        <strong>(“Personal Information”)</strong> shared with us. Personal
        Information may include, but is not limited to, your name, address,
        email address, contact information, bank account/Credit/Debit Card
        details and other personal details. This privacy policy extends to data
        collected from users who are not registered as members of this site, but
        browse, access or view the site, for informational and other purposes.
      </p>
    </div>
  );
};

const PrivatePolicy = () => {
  return (
    <div className=" bg-white font-manrope relative overflow-hidden h-full w-screen   border-gray-300 rounded-b-[70px] md:rounded-b-[150px] ">
      <section className="py-0 md:py-20">
        <div>
          <PrivatePolicyHeader />
          <PrivatePolicyContent />
          <div className="py-2 pb-10 md:pb-0 mx-5 md:mx-60">
            {PRIVACY_POLICY_DATA.map((term, i) => (
              <CollapsibleSection
                key={`policy_index_${i}`}
                title={term.title}
                content={term.content}
                icon={term.thumb}
              />
            ))}
          </div>
        </div>
      </section>
      <hr />
    </div>
  );
};

export default PrivatePolicy;
