import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import { AppProvider } from "./provider/AppContext";

import { scrollTopImage } from "./Data";
import ScrollToTop from "./service/service";

const Layout = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;
      if (scrollPosition > windowHeight / 2) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <AppProvider>
      <div className="layout  w-full overflow-hidden  bg-footer-primary">
        <Header />
        <main className="content ">
          <Outlet />
          {showButton && (
            <img
              src={scrollTopImage}
              onClick={scrollToTop}
              className="fixed bottom-16 right-5 md:bottom-4 md:right-10 z-10"
              alt=""
            />
          )}
          <ScrollToTop />
        </main>
        <Footer />
      </div>
    </AppProvider>
  );
};

export default Layout;
