import React from "react";
import TopicSection from "./TopicSection/TopicSection";
import TestimonalSection from "./TestimonalSection/TestimonalSection";
import PhoneBanner from "./BannerSection/PhoneBanner";

const LandingPage = () => {
  return (
    <div className=" relative overflow-hidden h-full w-screen md:h-full  border-gray-300 rounded-b-[70px] md:rounded-b-[150px] ">
      <TopicSection />
      <TestimonalSection />
      <PhoneBanner />
    </div>
  );
};

export default LandingPage;
