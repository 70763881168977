import React, {useState,useEffect } from 'react';
import './Navbar.scss';
// import { getSignedUrl} from '../../awsConfig'
import {storeRedirect} from '../service/service'
import { data } from '../Data';

// const navigation = [
//   { name: 'Discover', href: '', current: true, icon: 'fas fa-tachometer-alt' },
//   { name: 'Learn', href: 'testing', current: false, icon: 'fas fa-users' },
//   { name: 'About Us', href: '#', current: false, icon: 'fas fa-briefcase' },
// ];

// const imageKeys = ['images/logo.svg'];

const Navbar = () =>{
  // const { isMobileNavOpen, toggleMobileNav } = useContext(AppContext);

    const [scrolling, setScrolling] = useState(false);

    const images = data.ec2

    // const [imageUrls, setImageUrls] = useState({});

    // useEffect(() => {
    //   // Fetch all image URLs and store them as an object
    //   const fetchImageUrls = async () => {
    //     const urls = await Promise.all(
    //       imageKeys.map(async (key) => {
    //         const url = await getSignedUrl(key); // Fetch signed URL
    //         return { [key]: url }; // Return as an object with key as the filename
    //       })
    //     );
        
    //     // Merge all the individual objects into one object
    //     const urlObject = Object.assign({}, ...urls);
    //     setImageUrls(urlObject);
    //   };
      
    //   fetchImageUrls();
    // }, [imageKeys]); // Dependency on imageKeys
  

    useEffect(() => {
      const handleScroll = () => {
        if (window.scrollY >55) {
          setScrolling(true);
        } else {
          setScrolling(false);
        }
      };

      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);

  return (  
  <nav className={` fixed w-full z-50 transition-all duration-300 ${
    scrolling
      ? 'bg-white/80 shadow-lg'
      : 'bg-white backdrop-blur-lg shadow-md'
  }`}>
        <div className={`max-w-9xl mx-auto px-4 sm:px-6 | md:px-8`}>
          <div className="flex justify-between items-center h-25">

          {/* Logo Section */}
          <div className="flex items-start">
                <img
                  src={`${images}logo.svg`}
                  alt="Logo"
                  className="flex-shrink-0 h-14 flex object-contain | md:h-20"
                />
              </div>
  
            {/* Desktop Menu */}
            {/* <div className="hidden space-x-6 items-center | md:flex ">
                <button   className=" shine-effect px-4 py-2 bg-secondary-color text-white rounded-3xl  shadow  cursor-pointer" onClick={storeRedirect}>Download&nbsp;Now</button>
            </div> */}
  
            {/* Mobile Menu Button */}
            {/* <div className="md:hidden flex items-center">
              <button
                onClick={() => toggleMobileNav(!isMobileNavOpen)}
                type="button"
                className="text-gray-500 hover:text-gray-700 focus:outline-none"
              >
                <svg
                  className="h-6 w-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d={isMobileNavOpen ? 'M6 18L18 6M6 6l12 12' : 'M4 6h16M4 12h16m-7 6h7'}
                  />
                </svg>
              </button>
            </div> */}
            <div className="md:hidden flex items-center">
                <button   className="font-manrope shine-effect text-sm w-26 h-50 px-4 py-2 bg-secondary-color text-white rounded-3xl  shadow  cursor-pointer" onClick={storeRedirect}>Download&nbsp;Now</button>
            </div>
          </div>
        </div>
  
        {/* Mobile Menu */}
        {/* {isMobileNavOpen && (
          <div className="md:hidden bg-white">
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              {navigation.map((item) =>
                item.isButton ? (
                  <a
                    
                    key={item.name}
                    href={item.href}
                    className="block text-center px-4 py-2 bg-brown-500 text-white rounded-md shadow hover:bg-brown-700"
                  >
                    {item.name}
                  </a>
                ) : (
                  <a
                    key={item.name}
                    href={item.href}
                    className="block text-center text-gray-700 hover:text-gray-900 px-3 py-2 rounded-md"
                  >
                    {item.name}
                  </a>
                )
              )}
            </div>
          </div>
        )} */}

        
      </nav>
  );
} 



export default Navbar;
