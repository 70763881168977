import React from "react";

import { TERMS_OF_SERVICE } from "../../Data";

import { CollapsibleSection } from "./CollapsibleSection";

const TermsOfServiceHeader = () => {
  return (
    <div
      className="relative bg-primary-color py-20 bg-cover bg-center"
      style={{
        backgroundImage: `url('https://equentis-web.s3.ap-south-1.amazonaws.com/RNR_old/bg_whocaninvest.jpg')`,
      }}
    >
      <div className="absolute inset-0 bg-primary-color opacity-70"></div>
      <div className="relative z-10 md:ml-40 mx-auto  flex justify-center  md:justify-start md:pt-0 pt-12">
        <h1 className="text-2xl md:text-4xl font-bold text-white">
          TERMS OF SERVICE
        </h1>
      </div>
    </div>
  );
};

const TermsOfServiceContent = () => {
  return (
    <div className="mx-auto px-6 py-10  md:py-16 md:px-60 text-center font-pillGothic md:text-lg text-sm ">
      <h2 className="text-3xl  text-gray-800 mb-4">
        <strong>TERMS OF SERVICE</strong>
      </h2>

      <p className="mt-8   uppercase md:text-center text-left">
        <strong>PLEASE READ THIS TERMS OF SERVICE AGREEMENT CAREFULLY.</strong>
        &nbsp; CAREFULLY. BY USING THIS WEBSITE OR APPLICATION (the{" "}
        <strong>&quot;PLATFORM&quot;</strong>) OR SUBSCRIBING TO ANY
        SERVICE(S)/SUBSCRIPTION(S) FROM THIS PLATFORM, YOU AGREE TO BE BOUND BY
        ALL OF THE TERMS AND CONDITIONS OF THIS AGREEMENT.
      </p>

      <p className="mt-8 text-gray-700 text-left">
        This Terms of Service Agreement (the{" "}
        <strong>&quot;Agreement&quot;</strong>) governs your use of this
        Platform,{" "}
        <a href="https://www.equentisedutech.com">
          https://www.equentisedutech.com
        </a>{" "}
        (“Equentis - Informed InvestoRR” or the “Platform”). &quot;Equentis -
        Informed InvestoRR&quot; is the brand under which Equentis Edutech
        offers various free and paid course(s), workshop(s) and podcast(s) on
        this Platform. Furthermore, please note that the terms “Equentis
        Edutech” and “Equentis - Informed InvestoRR” may be used interchangeably
        throughout this agreement, as applicable. This Agreement hereby
        incorporates, by means of reference herein, the policies and guidelines
        specified below. Equentis Edutech reserves the right to change or revise
        the terms and conditions of this Agreement at any time by posting any
        changes or a revised Agreement on this Platform.
      </p>

      <p className="mt-4 text-gray-700 text-left">
        The changed or revised Agreement will take effect immediately after
        being posted on this Platform. Your continued use of the Platform
        following the posting of any such changes or a revised Agreement will
        indicate your acceptance of those changes or revisions. Equentis Edutech
        encourages you to review this Agreement whenever you visit the Platform
        to ensure that you understand the terms and conditions governing its
        use. This Agreement does not modify the terms or conditions of any other
        written agreement you may have with Equentis Edutech for other Services
        or services. If you do not agree to this Agreement (including any
        referenced policies or guidelines), please promptly cease your use of
        the Platform.
      </p>
    </div>
  );
};

const TermOfService = () => {
  return (
    <div className=" bg-white font-manrope relative overflow-hidden h-full w-screen md:h-full  border-gray-300 rounded-b-[70px] md:rounded-b-[150px] ">
      <section className="py-0 md:py-20">
        <div>
          <TermsOfServiceHeader />
          <TermsOfServiceContent />

          <div className="py-2 mx-5 md:mx-60 pb-10 md:pb-0">
            {TERMS_OF_SERVICE.general.map((term, i) => (
              <CollapsibleSection
                key={`term_index_${i}`}
                title={term.title}
                content={term.content}
                icon={term.thumb}
              />
            ))}
          </div>
        </div>
      </section>
      <hr />
    </div>
  );
};
export default TermOfService;
