import React from 'react';
import FooterSection1 from './FooterSection/FooterSection1';
import FooterSection2 from './FooterSection/FooterSection2';

const Footer = () => {
  return (
    <footer>
   <FooterSection1/>
   <FooterSection2/>
  </footer>
  );
};

export default Footer;
