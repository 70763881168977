import React, { useState } from "react";

export const CollapsibleSection = ({ title, content, icon }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="relative flex items-start py-2 md:py-2 ">
      {/* Vertical Line */}
      <div className="vertical_line_icons"></div>

      {/* Icon */}
      <div className="hidden md:flex relative z-10">
        {/* Icon */}
        <div className="bg-white rounded-full p-1">
          <img
            src={icon}
            alt={title}
            className="w-20 h-20 rounded-full border border-gray-300"
          />
        </div>
      </div>

      {/* Collapsible Section */}
      <div className="md:ml-2 flex-1 w-40">
        {/* Title Bar */}
        <div
          className="flex justify-between items-center p-6 cursor-pointer bg-primary-color text-white rounded-sm"
          onClick={toggleOpen}
        >
          <h2 className="text-lg font-manrope capitalize">
            <strong>{title}</strong>
          </h2>

          <span className="text-white">
            <strong>{isOpen ? "-" : "+"}</strong>
          </span>
        </div>

        {/* Accordion Content with smooth transition */}
        <div
          className={`overflow-hidden transition-max-height duration-500 ease-in-out ${
            isOpen ? "max-h-screen" : "max-h-0"
          }`}
        >
          <div className="p-8 bg-white text-gray-700 border border-t-transparent border-gray-300 font-pillGothic">
            {content}
          </div>
        </div>
      </div>
    </div>
  );
};
