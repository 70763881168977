import React, { useState, useEffect } from 'react';
import barcode from "./../../../../assets/images/barcode.svg";


import { data } from '../../../Data';

import phoneBackground from '../../../../assets/images/background_layer/banner_bg.svg'

import phoneBackgroundMob from '../../../../assets/images/background_layer/banner_bg_mobile.svg'

import phoneImage from '../../../../assets/images/phone.svg'



const PhoneBanner = () => {
  const images  = data.ec2
  // const [bgImage, setBgImage] = useState(`${images}background_layer/banner_bg.png`);

  const [bgImage, setBgImage] = useState(phoneBackground);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setBgImage(phoneBackgroundMob); 
      } else {
        setBgImage(phoneBackground); 
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div 
      className="w-full h-full bg-[#F9F5F1]"
          >
      <div className="h-full w-full bg-center bg-cover bg-no-repeat   ml-2 justify-around lg:h-full xl:h-full lg:xl:w-full xl:w-full  flex flex-col md:flex-row items-center relative" 
      style={{ backgroundImage: `url(${bgImage})`}}>
        {/* Mobile App Image */}
        <div className="relative mx-auto  z-10 items-center pb-0 md:pb-3 w-full lg:w-4/12">
          <img
            src={phoneImage}
            alt="Mobile App Preview"
            className="w-full mx-5 relative -mt-[8rem] | md:mx-0  md:-mt-60"
          />
        </div>

        {/* Text and Download Buttons */}
        <div className="hidden md:block mx-auto md:ml-28  xl:ml-24   | lg:w-1/2">
          <h2 className="md:text-[40px] xl:text-[45px]   text-[#0F0F0F] font-pillGothic ">
            #KnowledgeMein<span className="text-secondary-color font-manrope font-[700]">EDGE</span>Hai
          </h2>
          <p className="text-3xl md:text-2xl text-[#0F0F0F] mt-4 font-pillGothic" >Download App From</p>

          {/* Download Buttons */}
          <div className="mt-6 mb-3 flex space-x-4">
            <a href={data.google_play_store} target="_blank" rel="noopener noreferrer">
              <img src={`${images}app_store/play_store.svg`} alt="Google Play" className="w-44" />
            </a>
            <a href={data.apple_store} target="_blank" rel="noopener noreferrer">
              <img src={`${images}app_store/apple_store.svg`} alt="App Store" className="w-44" />
            </a>
          </div>
        </div>

        {/* Mobile Layout */}
        <div className="block md:hidden mx-auto pt-10">
          <h2 className="text-2xl text-center  text-[#0F0F0F] font-pillGothic">
            #KnowledgeMein<span className="text-secondary-color font-manrope font-[700]">EDGE</span>Hai
          </h2>
          <p className="text-[20px] text-center text-[#5B5B5B] mt-2 font-pillGothic">Download App From</p>

          {/* Download Buttons for Mobile */}
          <div className="flex justify-center space-x-4 mt-1  mb-2">
            <a href={data.google_play_store} target="_blank" rel="noopener noreferrer">
              <img src={`${images}app_store/play_store.svg`} alt="Google Play" className="w-28 h-20 object-contain" />
            </a>
            <a href={data.apple_store} target="_blank" rel="noopener noreferrer">
              <img src={`${images}app_store/apple_store.svg`} alt="App Store" className="w-28 h-20 object-contain" />
            </a>
          </div>
        </div>

        {/* QR Code */}
        <div 
          className="hidden md:flex-row w-1/5 absolute top-8 md:top-12 right-8 md:right-7 z-10"
          style={{ marginTop: '-13rem' }}
        >
          <p className="md:text-xl text-black mt-20 flex items-end text-start">
            Scan&nbsp;QR&nbsp;to&nbsp;download&nbsp;the<br/>app&nbsp;now
          </p>
          <img
            src={barcode}
            alt="Scan QR to download"
            className="w-20 md:w-28 bg-[#F9F5F1] bg-opacity-60 p-2 ml-2"
          />
        </div>
      </div>
    </div>
  );
}

export default PhoneBanner;
