import React, { createContext, useState } from 'react';

const AppContext = createContext();

const AppProvider = ({ children }) => {
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

  const toggleMobileNav = () => {
    setIsMobileNavOpen(!isMobileNavOpen);
  };

  return (
    <AppContext.Provider value={{ isMobileNavOpen, toggleMobileNav }}>
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
