import React,{useState,useEffect} from 'react';
import Navbar from '../Navbar/Navbar';
import { storeRedirect } from '../service/service';

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    const handleScroll = () => {      
      if (window.scrollY > 100) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    checkMobile();
    window.addEventListener('resize', checkMobile);
    window.addEventListener('scroll', handleScroll); 
    return () => {
      window.removeEventListener('resize', checkMobile);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


return(
  <>
    <header className={`md:z-50 md:sticky md:top-0 ${isMobile && isScrolled ?'hidden':''}`}>
      <Navbar />
    </header>

         {/* Mobile-specific download button */}
         {isMobile && isScrolled && (
        <nav className="fixed flex justify-center  h-18 p-2   w-full  bottom-0 left-1/2 transform -translate-x-1/2 z-50 bg-white shadow-lg">
        <button   className="shine-effect  text-lg px-4 py-2 bg-secondary-color text-white rounded-3xl  shadow  cursor-pointer" onClick={storeRedirect}>Download&nbsp;Now</button>
        </nav>
      )}
  </>

)

  
};

export default Header;
