import React from "react";
import { data } from "../../../../Data";

const TopicSectionVideo = () => {
  let images = data.ec2;
  let apple_store = `${images}app_store/apple_store.svg`;
  let play_store = `${images}app_store/play_store.svg`;
  let primaryBgImage = `${images}background_layer/primary_banner.png`;

  const primary = {
    backgroundImage: `url(${primaryBgImage})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  return (
    <div
      className="flex bg-bgSecondary lg:h-[700px] -mb-2 md:-mb-16 pt-28 md:pt-1 lg:pt-2    px-4 md:px-36 lg:px-24 xl:px-24"
      style={primary}
    >
      {/* Flex Container */}
      <div className="sm:wrap flex flex-col sm:flex-col-reverse lg:flex-row sm:items-center  md:pt-80 lg:pt-0">
        {/* Mobile Right Side Video */}
        <div className="flex sm:hidden flex-col   max-w-6xl shrink-0 z-10 mx-auto ">
          <video
            autoPlay
            muted
            loop
            playsInline
            className=" object-contain  -mb-4 border-1 rounded-xl"
          >
            <source src={data.ec2_video} type="video/mp4" />
          </video>
        </div>

        {/* Left Side Content */}
        <div className="md:h-1/2 md:w-1/2  z-10 flex flex-col justify-center   self-center  pt-10  ">
          <h1 className="font-manrope  text-center text-[22px]   text-white mb-4 | xl:text-[40px] 2xl:text-[45px] lg:text-start xl:text-start 2xl:text-start">
            We help you Create Wealth with{" "}
            <span className="text-secondary-color font-manrope font-[700]">
              Clarity,
            </span>{" "}
            <span className="text-secondary-color font-manrope font-[700]">
              Consistency , <br />
            </span>
            <span className="text-secondary-color font-manrope font-[700]">
              &&nbsp;Compounding
            </span>
          </h1>

          {/* App Download Section */}
          <div className="flex flex-col md:flex-row h-full w-full items-center lg:items-start  ">
            <div className="flex flex-row  gap-2  w-full h-[100px] lg:h-[50px] lg:justify-start justify-center items-start md:items-center">
              <a href={data.google_play_store} target="_blank" rel="noreferrer">
                <img
                  src={play_store}
                  alt="Google Play"
                  className="w-[120px] h-[40px] lg:w-[140px] lg:h-[50px] object-contain "
                />
              </a>

              <a href={data.apple_store} target="_blank" rel="noreferrer">
                <img
                  src={apple_store}
                  alt="App Store"
                  className="w-[120px] h-[40px] lg:w-[140px] lg:h-[50px] object-contain"
                />
              </a>
            </div>

            {/* QR Code (Hidden on mobile) */}
            {/* <a  className="w-44 h-44 md:w-50 md:h-full mt-10 mb-4 md:mb-0 hidden md:block bg-primary-color" href='https://me-qr.com' >
            <img src='https://cdn.me-qr.com/qr/127464930.png?v=1727245606' alt='Create qr code for free'/></a>
             */}

            {/* <div className="flex flex-col items-center |  md:items-start   mt-2 md:mt-5">
              <p className="font-pillGothic text-[25px] text-white mb-6 hidden  | md:block">Scan to Download App</p>
            </div> */}
          </div>
        </div>

        {/* Right Side Video */}
        <div className="hidden md:flex flex-col  md:w-1/2    shrink-0 z-10  md:ml-auto  items-end my-auto ">
          <video
            autoPlay
            muted
            loop
            playsInline
            className="h-[430px] w-[500px]      object-cover border-1 rounded-xl brightness-100"
            poster={data.ec2_video}
            disablePictureInPicture={true}
            disableRemotePlayback={true}
          >
            <source src={data.ec2_video} type="video/mp4" />
          </video>
        </div>
      </div>
    </div>
  );
};

export default TopicSectionVideo;
