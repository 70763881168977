import React from "react";
import { data } from "../../../../Data";
import second_banner from "../../../../../assets/images/background_layer/second_banner.svg";

import second_banner_mobile from "../../../../../assets/images/background_layer/second_banner_mobile.svg";

const TopicSectionBanner = () => {
  let images = data.ec2;
  const bannerData = [
    {
      count: "80k+",
      context: "App Downloads",
      icon: `${images}icon/app_download.svg`,
    },
    {
      count: "57k",
      context: "Informed InvestorRRs",
      icon: `${images}icon/informed_investorr.svg`,
    },
    {
      count: "20+",
      context: "Yrs of Investment Experience",
      icon: `${images}icon/yrs_investment_exp.svg`,
    },
  ];

  const background_desktop = {
    backgroundImage: `url(${second_banner})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  };

  const background_mobile = {
    backgroundImage: `url(${second_banner_mobile}`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  };
  return (
    <>
      {/* Desktop */}
      <div className="hidden md:flex md:h-[130px]   bg-[#F4F4F4]">
        <ul
          className="flex flex-col md:flex-row w-full items-start md:items-center justify-around  pl-2"
          style={background_desktop}
        >
          {bannerData.map((e, i) => (
            <li
              key={i}
              className="flex flex-col md:flex-row md:items-center items-start mb-6 md:mb-0  "
            >
              <img
                className=" md:h-16 md:w-16 text-secondary-color mb-2 "
                src={e.icon}
                alt="icon"
              />
              <div className="ml-3 text-left ">
                <h2 className="text-[50px] font-[700] font-manrope text-secondary-color">
                  {e.count}
                </h2>
                <p className="text-[20px]  text-primary-text">{e.context}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>

      {/* Mobile */}
      <div className="h-full flex md:hidden   bg-[#F4F4F4] ">
        <ul
          className=" flex flex-col md:flex-row w-full items-start md:items-center justify-around   b-[100%] bg-top "
          style={background_mobile}
        >
          {bannerData.map((e, i) => (
            <li key={i} className="flex flex-row  md:items-center items-center">
              <img
                className="w-18 h-18  md:h-16 md:w-16 text-secondary-color mx-2 my-5"
                src={e.icon}
                alt="icon"
              />
              <div className="ml-2 text-left">
                <h2 className="text-[38px]  font-bold text-secondary-color font-manrope">
                  {e.count}
                </h2>
                <p className="text-[16px] text-primary-text font-pillGothic">
                  {e.context}
                </p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default TopicSectionBanner;
