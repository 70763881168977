import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Layout from './components/Layout';
import LandingPage from './components/Pages/LandingPage/LandingPage';
import './styles.css';
import TermsOfService from './components/Pages/Information/TermOfService';
import PrivatePolicy from './components/Pages/Information/PrivatePolicy';


// Define routes with Layout
const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />, 
    children: [
      {
        path: '',
        element: <LandingPage />, 
      },
      {
        path: 'term-of-service',
        element: <TermsOfService />, 
      },
      {
        path: 'privacy-policy',
        element: <PrivatePolicy />, 
      },
    ],
  },
]);

const App = () => {
  return (
    
    <RouterProvider router={router} />
  );
};

export default App;
