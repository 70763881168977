import React from "react";

const FooterSection2 = () => {
  return (
    <div className="bg-footer-primary  text-white   pb-[80px] px-7 py-4 |  md:text-xl md:px-14 md:py-8">
      <div className="container ml-1 text-[#A8A8A8] ">
        <h4 className="text-[14px] lg:text-xl mb-4 font-manrope font-[700] text-white">
          Disclosures and Disclaimer:
        </h4>
        <ul className="list-decimal ml-3 space-y-1 text-[14px] lg:text-[16px] font-pillGothic">
          <li>
            Person(s) forming the part of the video may or may not be an
            employee of Equentis Edutech Limited{" "}
            <strong className="text-white font-manrope text-sm">
              ("Equentis Edutech")
            </strong>{" "}
            or its affiliates, holding, subsidiary(ies), or its group
            company(ies).
          </li>
          <li>
            The Official Website of Equentis Edutech is{" "}
            <a
              href="https://www.equentisedutech.com"
              className="text-blue-400 hover:underline"
            >
              https://www.equentisedutech.com
            </a>
            .
          </li>
          <li>
            "Equentis - Informed InvestorRR" is the brand under which Equentis
            Edutech publishes educational contents/videos.
          </li>
          <li>
            <strong className="font-manrope text-sm text-white">
              The Video/Content is prepared and distributed by Equentis Edutech
              for information and Education purpose only.
            </strong>{" "}
            Stocks/companies/scrips/securities or
            Illustration/explanation/expressions, if any given in the video is
            for illustration/example and Educational purpose only and shall not
            be deemed or construed to be advice for the purpose of buy or sell
            of any stocks/scrips/securities, derivatives or any other security
            in the market.
          </li>
          <li>
            The information/opinions/views are not meant to serve as a
            professional investment or financial planning guide for the
            readers/viewers. No action is solicited based upon the information
            provided herein. Viewers and listeners should rely on
            information/data arising out of their own investigations. The
            video/content have been prepared without taking account of your
            objectives, financial situation or needs. Viewers and listeners are
            advised to seek independent professional advice before executing any
            investments or financial planning decisions. The contents of the
            video have been prepared based on publicly available information,
            internally developed data and other sources believed by Equentis
            Edutech to be reliable. Equentis Edutech or its directors,
            employees, affiliates, or representatives do not hold any
            responsibility for, or warrant the accuracy completeness, adequacy,
            and reliability of such information / opinions / views. While due
            care has been taken to ensure that the disclosures and opinions
            given are fair and reasonable, none of the directors, employees,
            affiliates, or representatives of Equentis Edutech shall be liable
            for any direct, indirect, special, incidental, consequential,
            punitive, or exemplary damages, including lost profits arising in
            any way whatsoever from the information / opinions / views contained
            in the Video/Content.
          </li>
          <li>
            Risks: Trading and Investment in securities Market are subject to
            market risks. Read all the related documents carefully before
            investing. There are no assurances or guarantees that the objectives
            of any of investment in securities will be achieved.
          </li>
          <li>
            The possession, circulation and/or distribution of the video/content
            may be restricted or regulated in certain jurisdictions by
            appropriate laws and therefore the Video/content will be governed by
            and constructed in accordance with applicable law of India and
            dispute relating to the video/content will be subject to exclusive
            jurisdiction of courts of Mumbai, Maharashtra, India.
          </li>
          <li>
            <strong className="font-manrope text-sm text-white">
              Copyright:
            </strong>{" "}
            The copyright in the video/content belongs exclusively to Equentis
            Edutech. The Video/content shall only be views/referred by those
            persons to whom it has been delivered. No printing, reproduction,
            copying, distribution of the video/content in any manner whatsoever,
            in whole or in part, is permitted, without the prior express written
            consent of Equentis Edutech.
          </li>
        </ul>
        <p className="text-xs mt-6 font-pillGothic">
          These disclaimers, risks and other disclosures must be read in
          conjunction with the information / opinions / views of which they form
          part of.
        </p>
        <p className="text-xs text-[#A8A8A8] mt-6 font-manrope">
          Copyright © 2024 Equentis Edutech Limited. All Rights Reserved.
        </p>
      </div>
    </div>
  );
};

export default FooterSection2;
