import React from "react";

import TestimonialSlider from "./utility/TestimonalSlider";

import { data } from "../../../Data";

const TestimonalSection = () => {
  return (
    <div className="w-screen relative bg-white pb-44 lg:pb-72">
      <div className="relative z-10">
        <div
          className="flex top-[30%]   absolute bg-testimonal-background w-full h-[66%] border-transparent z-0  |   md:top-[35%] md:right-[17%]   md:w-2/3 md:h-2/3"
          alt=""
        />

        <TestimonialSlider />
      </div>
      <img
        src={`${data.ec2}miscellanous/back1.svg`}
        alt=""
        className="absolute w-8 top-4 md:top-12  xl:w-14"
      />
      <img
        src={`${data.ec2}miscellanous/back2.svg`}
        className="absolute  bottom-28 md:bottom-60 right-4 md:right-[6rem] w-5 lg:w-5"
        alt=""
      />
    </div>
  );
};

export default TestimonalSection;
