import React from 'react';



const Testimonial = ({ testimonial }) => {
  return (
    <div className="keen-slider__slide 
    w-full
    h-full
    p-5
     bg-card-gradient text-[#F4F4F4] shadow-[4px 0px 10px 0px rgba(0, 0, 0, 0.10), 0px 4px 10px 0px rgba(0, 0, 0, 0.10)] rounded-md shadow-lg flex flex-col ">
    <div className="h-1/2 ">
      <p className="text-xl md:text-xl lg:text-xl xl:text-xl font-light mb-4 font-pillGothic"><q>{testimonial.text}</q></p>

    </div>

    <div className="flex items-end justify-start h-1/2 ">
      <img
        src={testimonial.image}
        alt={testimonial.name}
        className="w-14 h-14 lg:w-14 lg:h-14 xl:w-18 xl:h-18 rounded-full object-cover mr-4"
      />
      <div>
        <h3 className="text-sm md:text-sm xl:text-lg font-semibold font-manrope">{testimonial.name}</h3>
        <p className="text-sm md:text-sm xl:text-lg opacity-70 font-pillGothic">{testimonial.title}</p>
      </div>
    </div>
  </div>
  );
};

export default Testimonial;
