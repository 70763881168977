import scrollTop from "../assets/images/icon/scroll_arrow_top.svg";

export const data = {
  google_play_store: process.env.REACT_APP_GOOGLE_PLAY_STORE,
  apple_store: process.env.REACT_APP_APPLE_STORE,
  youtube_video_link: process.env.REACT_APP_Youtube_Video_URL,
  ec2: process.env.REACT_APP_ASSET + "images/",
  ec2_video: process.env.REACT_APP_ASSET + "video/product_irr_video .mp4",
};

export const scrollTopImage = scrollTop;

export const PRIVACY_POLICY_DATA = [
  {
    thumb:
      "https://equentis-web.s3.ap-south-1.amazonaws.com/RNR_old/terms/gen_01.png",
    title:
      "Notification of Modifications and Changes to the T&C and Privacy Policy",
    content: (
      <div class="panel-body">
        <p>
          We reserve the right to modify the Terms and Privacy Policy from time
          to time as deemed appropriate, without any prior notification to you.
          Your ongoing usage of the site will indicate your acceptance of any
          revisions to these terms.
          <br />
          You are advised to regularly review the Terms of Service. If you do
          not agree to any modifications or amendments to the Terms, you should
          immediately cease your use of this Platform and the services offered
          through it.
        </p>
      </div>
    ),
  },
  {
    thumb:
      "https://equentis-web.s3.ap-south-1.amazonaws.com/RNR_old/terms/gen_02.png",
    title: "Information we collect",
    content: (
      <div class="panel-body">
        <p>
          <li>
            To gain access to our courses, workshops, and podcasts via our
            Platform, you are obligated to provide your contact details.
            However, if you intend to subscribe to our paid courses, workshops,
            and podcasts, you will be required to furnish the following
            information, including but not limited to:
          </li>
          <li>Name;</li>
          <li>E-mail address;</li>
          <li>Phone /Mobile Number;</li>
          <li>Address;</li>
          <li>Pin code;</li>
          <li>Age and Gender;</li>
          <li>Bank Details;</li>
          <li>Profession;</li>
          <br />
          By furnishing the Personal Information of another person, you
          implicitly affirm that you possess all requisite authority and/or have
          acquired all necessary consents from such individual to permit us to
          collect, use and disclose his or her Personal Information as
          delineated in this Privacy Policy.
          <br />
          Please note that by providing us with your contact details on our
          Platform, you agree to the following terms and conditions.
        </p>
      </div>
    ),
  },
  {
    thumb:
      "https://equentis-web.s3.ap-south-1.amazonaws.com/RNR_old/terms/gen_03.png",
    title: "Consent to Contact",
    content: (
      <div class="panel-body">
        <p>
          By signing up on the Platform, you provide us and our affiliates,
          subsidiaries, holding or group companies, with your consent to contact
          you via your phone number and email address for the purpose of
          furnishing information about our services which includes course(s),
          workshop(s) and podcast(s) and any promotional offers. This consent
          supersedes any registration you may have made on the National Do Not
          Call (DNC) registry in India.
        </p>
        <br />
        <p>
          Nature of Calls: The calls made to your phone number will pertain to
          our services and may include telemarketing calls.
        </p>
        <br />
        <p>
          Opt-Out: You reserve the right to withdraw your consent to receive
          telemarketing calls at any time by sending an email at{" "}
          <a
            className="text-primary-color underline"
            href="mailto:support.informedinvestorr@equentisedutech.com"
          >
            support.informedinvestorr@equentisedutech.com
          </a>{" "}
          .{" "}
        </p>
        <br />
        <p>
          We are dedicated to safeguarding your personal information and
          privacy. Your phone number will solely be use for communicating
          regarding our services and will not be disclosed to any third parties
          except to our affiliates, subsidiaries, holding or group companies.
        </p>
      </div>
    ),
  },
  {
    thumb:
      "https://equentis-web.s3.ap-south-1.amazonaws.com/RNR_old/terms/gen_03.png",
    title: "Cookies",
    content: (
      <div class="panel-body">
        <p>
          We use data collection devices such as “cookies” on certain pages of
          our website or application (the <strong>“Platform”</strong>).
          “Cookies” are small files sited on your hard drive that assist us in
          providing customized services. We also offer certain features that are
          only available through the use of a “cookie”. Cookies help us provide
          information, which is targeted to your interests. Cookies may be used
          whether you register with us or not.
        </p>
      </div>
    ),
  },
  {
    thumb:
      "https://equentis-web.s3.ap-south-1.amazonaws.com/RNR_old/terms/gen_04.png",
    title: "External Links on Platform",
    content: (
      <div class="panel-body">
        <p>
          Our Platform may include links or hyperlinks to other third-party
          website(s) or application(s). We do not exercise any control over
          these external website(s) or application(s), as they are provided by
          other entities or person(s). We shall not be held responsible for the
          privacy policies and information practices of these third-party
          website(s) or application(s). It is advisable to review the privacy
          policies of such third- party website(s) or application(s), as each
          may have distinct terms of use and privacy policies.
        </p>
      </div>
    ),
  },
  {
    thumb:
      "https://equentis-web.s3.ap-south-1.amazonaws.com/RNR_old/terms/gen_05.png",
    title: "Our Use of Your Information",
    content: (
      <div class="panel-body">
        <p>First and foremost, your information is used to:</p>
        <br />
        <p>
          {" "}
          <li>
            contact you when necessary. We use your IP address to help diagnose
            problems with our server, and to administer our Platform. Your IP
            address is also used to help identify you and to gather broad
            demographic information. Further, we may use your IP address to help
            protect our partners, vendors and ourselves from fraud. We will
            transfer information about you if we are acquired by or merged with
            another company. In this event, we will notify you by email or by
            putting a notice on the site before information about you is
            transferred and becomes subject to a different privacy policy.
          </li>
          Without limitation to the foregoing, your information may be used:
          <li>
            To develop, enhance, market, sell or provide our services, or those
            of companies with which we have a commercial relationship;
          </li>
          <li>
            To issue invoices, administer accounts, collect and process
            payments;
          </li>
          <li>
            To provide tips or guidance on how to use our Platform, inform you
            of new features on our Platform, or provide other information that
            may be of interest to you;
          </li>
          <li>
            To send you e-mails, e-newsletters, personalized offers via direct
            messaging or other communications about our services, if you have
            subscribed to receive this information;
          </li>
          <li>To process and track your transactions;</li>
          <li>
            To effect a corporate transaction, in connection with the sale,
            merger, spin-off or other corporate reorganization of our
            corporation, where the information is provided to the new
            controlling entity in regular course of business;
          </li>
          <li>
            To effect a corporate transaction, in connection with the sale,
            merger, spin-off or other corporate reorganization of our
            corporation, where the information is provided to the new
            controlling entity in regular course of business;
          </li>
          <li>
            To audit compliance with our policies and contractual obligations;
          </li>
          <li>
            As permitted by, and to comply with, any legal or regulatory
            requirements or provisions; or for any other purpose to which you
            consent.
          </li>
        </p>
      </div>
    ),
  },
  {
    thumb:
      "https://equentis-web.s3.ap-south-1.amazonaws.com/RNR_old/terms/gen_06.png",
    title: "Confidentiality",
    content: (
      <div class="panel-body">
        <p>
          You further acknowledge that the Platform may contain information
          designated confidential by us, and you agree not to disclose such
          information without our prior written consent.
          <br />
          Your information is considered confidential and will not be disclosed
          to any third party except as outlined in this and any other term of
          use for our Platform.
        </p>
      </div>
    ),
  },
  {
    thumb:
      "https://equentis-web.s3.ap-south-1.amazonaws.com/RNR_old/terms/gen_07.png",
    title: "Our Disclosure of Your Information",
    content: (
      <div class="panel-body">
        <p>
          From time to time, we may disclose your Personal Information as
          follows:
        </p>
        <br />
        <p>
          {" "}
          <li>
            We may collaborate with other companies to offer you additional
            products or services, for example, through promotions. We will share
            Personal Information that is necessary for these other companies to
            provide the products or services that you have requested. This
            policy will not cover the use of your Personal Information by these
            other companies. We encourage you to read a company’s privacy policy
            before requesting any of its products or services.
          </li>
          <li>
            We may also disclose your information to our affiliates and
            non-affiliated business partners for their use both on our behalf
            and for their own business purposes. For example, our affiliates and
            business partners may use such information to send you information
            about their products, services, other information and materials that
            may be of interest to you.
          </li>
          <li>
            To protect ourselves against liability or prevent fraudulent
            activity; or where it is necessary to permit us to pursue available
            remedies or limit any damages that we may sustain.
          </li>
          <li>
            We cooperate with law enforcement inquiries, as well as other third
            parties to enforce laws, such as: intellectual property rights,
            fraud and other rights. We can (and you authorize us to) disclose
            any information about you to law enforcement and other government
            officials as we, in our sole discretion, believe necessary or
            appropriate, in connection with an investigation of fraud,
            intellectual property infringements, or other activity that is
            illegal or may expose us or you to legal liability.
          </li>
          <li>
            We gather data such as personally identifiable information and
            disclose such information in a non-personally identifiable manner to
            advertisers and other third parties for other marketing and
            promotional purposes. However, in these situations, we do not
            disclose to these entities any information that could be used to
            identify you personally. We may use third-party advertising
            companies to serve advertisements on our behalf. These companies may
            employ cookies and action tags (also known as single pixel gifs or
            web beacons) to measure advertising effectiveness. Any information
            that these third parties collect via cookies and action tags is
            completely anonymous.
          </li>
          <li>
            We may use and share the User information with reliable and reputed
            third-party payment gateway to whom we are associated in order to
            ensure swift and comfortable payment mechanism for the User. The
            third-party payment gateway and other payment transaction
            processors, have their own privacy policies in respect to the
            information we are required to provide to them for your
            purchase-related transactions. For these providers, we recommend
            that you read their privacy policies so you can understand the
            manner in which your personal information and your credit/debit card
            details will be handled by these providers. Once you leave our
            Platform or are redirected to a third-party website(s) or
            application(s), you are no longer governed by this Privacy Policy or
            our Platform’s Terms of Service.
          </li>
          <li>
            Due to the existing regulatory environment, we cannot ensure that
            all of your personally identifiable information will never be
            disclosed in ways not otherwise described in this Privacy Policy. By
            way of example (without limiting and foregoing), we may be forced to
            disclose information to the government, law enforcement agencies or
            third parties. Under certain circumstances, third parties may
            unlawfully intercept or access transmissions or members may abuse or
            misuse your information that they collect from our Platform.
            Therefore, we do not promise, and you should not expect, that your
            personally identifiable information or private communications would
            always remain private.
          </li>
        </p>
      </div>
    ),
  },
  {
    thumb:
      "https://equentis-web.s3.ap-south-1.amazonaws.com/RNR_old/terms/gen_08.png",
    title: "Accessing, Reviewing and Changing your Profile",
    content: (
      <div class="panel-body">
        <p>
          After registration, you can review and change the information you
          submitted during registration. If you change any information we may
          keep track of your old information. You can change your registration
          information such as: name, address, city, state, pin code, country,
          phone number, and profile.
          <br />
          We will retain in our files information you have requested to remove
          for certain circumstances, such as to resolve disputes, troubleshoot
          problems and enforce our terms and conditions. Further, such prior
          information is never completely removed from our databases due to
          technical and legal constraints, including stored ‘back up’ systems.
          Therefore, you should not expect that all of your personally
          identifiable information will be completely removed from our databases
          in response to your requests.
        </p>
      </div>
    ),
  },
  {
    thumb:
      "https://equentis-web.s3.ap-south-1.amazonaws.com/RNR_old/terms/gen_09.png",
    title: "Control of your Password",
    content: (
      <div class="panel-body">
        <p>
          When you sign up to become a subscriber, you will also be asked to
          choose a password. You are entirely responsible for maintaining the
          confidentiality of your password. It is important that you protect it
          against unauthorized access of your account and information by
          choosing your password carefully, and keeping your password and
          computer secure by signing out after using our services.
          <br />
          You agree not to use the account, username, email address or password
          of another subscriber at any time or to disclose your password to any
          third party. If you choose to share this information with third
          parties, you are responsible for all actions taken with your login
          information and password and therefore should review each third
          party’s privacy policy. You are responsible for all actions taken with
          your login information and password, including fees. If you lose
          control of your password, you may lose substantial control over your
          personally identifiable information and may be subject to legally
          binding actions taken on your behalf. Therefore, if your password has
          been compromised for any reason, you should immediately change your
          password. You agree to notify us immediately if you suspect any
          consistent unauthorized use of your account or access to your password
          even after changing it.
        </p>
      </div>
    ),
  },
  {
    thumb:
      "https://equentis-web.s3.ap-south-1.amazonaws.com/RNR_old/terms/gen_10.png",
    title: "Other Information Collectors",
    content: (
      <div class="panel-body">
        <p>
          Except as otherwise expressly included in this Privacy Policy, this
          document only addresses the use and disclosure of information we
          collect from you. To the extent that you disclose your information to
          other parties, whether they are on our Platform or on other sites
          throughout the Internet, different rules may apply to their use or
          disclosure of the information you disclose to them. To the extent that
          we use third party advertisers, they adhere to their own privacy
          policies. Since we do not control the privacy policies of third
          parties, you have every right to ask questions before you disclose
          your personal information to others.
        </p>
      </div>
    ),
  },
  {
    thumb:
      "https://equentis-web.s3.ap-south-1.amazonaws.com/RNR_old/terms/gen_11.png",
    title: "Disclaimer",
    content: (
      <div class="panel-body">
        <p>
          We cannot guarantee that all of your private communications and other
          personal information, including sensitive details like credit card
          information and bank account numbers, will never be disclosed in
          manners not explicitly outlined in this Privacy Policy. Consequently,
          while we are dedicated to safeguarding your privacy, we do not
          warrant, and you should not anticipate, that your personal information
          will perpetually remain confidential. As a user of the Platform, you
          acknowledge and consent that you assume full responsibility and risk
          for your utilization of the Platform, the internet at large, and the
          documents you upload or access, as well as for your behavior both on
          and off the Platform.
        </p>
      </div>
    ),
  },
  {
    thumb:
      "https://equentis-web.s3.ap-south-1.amazonaws.com/RNR_old/terms/gen_12.png",
    title: "Governing Law and Jurisdiction Clause",
    content: (
      <div class="panel-body">
        <p>
          This Agreement will be governed by the Laws of India, and any disputes
          arising hereunder shall be subject to the exclusive jurisdiction of
          the Courts of Mumbai.
        </p>
      </div>
    ),
  },
  {
    thumb:
      "https://equentis-web.s3.ap-south-1.amazonaws.com/RNR_old/terms/gen_13.png",
    title: "Refund Policy",
    content: (
      <div class="panel-body">
        <p>
          We strictly adhere to a <strong>'No Refund Policy'</strong> as we
          firmly uphold the quality and content of our course(s), workshop(s),
          and podcast(s). To facilitate an informed decision-making process, we
          offer access to various free course(s), workshop(s), and podcast(s)
          for preview before enrolling or subscribing to our paid offerings.
          Subsequent to these previews, you may elect to subscribe to our paid
          course(s), workshop(s) and podcast(s). Please note that all payments
          made for subscription to any paid course(s), workshop(s), or
          podcast(s) are non-refundable, non-reversible, and non-transferable.
        </p>
      </div>
    ),
  },
  {
    thumb:
      "https://equentis-web.s3.ap-south-1.amazonaws.com/RNR_old/terms/gen_14.png",
    title: "Account Deletion",
    content: (
      <div class="panel-body">
        <p>
          When your account is deleted, you will receive a confirmation email
          acknowledging the deletion. If you decide to restore your account
          after deletion, you may do so by sending an email to{" "}
          <a
            className="text-primary-color underline"
            href="mailto:support.informedinvestorr@equentisedutech.com"
          >
            support.informedinvestorr@equentisedutech.com
          </a>{" "}
          within 7 days of deletion. Your account reactivation will be processed
          within the following 72 hours. Please note that refunds are not
          provided under any circumstances.
        </p>
      </div>
    ),
  },
];

export const TERMS_OF_SERVICE = {
  general: [
    {
      thumb:
        "https://equentis-web.s3.ap-south-1.amazonaws.com/RNR_old/terms/gen_01.png",
      title: "Product",
      content: (
        <div class="panel-body">
          <p>
            Terms of Offer. This Platform offers the users access to our
            educational course(s), workshop(s) and podcast(s) (collectively, the
            <strong>“Services”</strong>) both free and paid fee. By registering
            or subscribing to the Services on the Platform, you expressly
            consent to and agree to be bound by the terms and conditions set
            forth in this Agreement.
          </p>
          <p>
            Subscription:&nbsp;We offer various educational content which
            includes course(s), workshop(s) and podcast(s)(both free as well as
            paid) on our Platform. As a registered user, you can access to our
            extensive library of free educational content without any additional
            cost/fee. Any registered user who after reviewing the free education
            content including the course(s), workshop(s) and podcast(s) is
            willing to subscribe to our paid offerings, can do so through our
            Platform.
          </p>
          <br />
          <p>
            The subscription fee for our paid services including course(s),
            workshop(s), and podcast(s), may vary based on the specific offering
            you have subscribed to. Upon successful payment of the subscription
            fee, you will gain immediate access to the subscribed course(s),
            workshop(s) and podcast(s).
          </p>
          <br />
          <p>
            Additionally, our Platform offers the user(s), an opportunity to
            earn free points by actively participating in assessments and
            quizzes associated with our course(s), workshop(s) and podcast(s)
            (both free and paid) as well as other periodical quizzes. These
            accumulated points can be redeemed in following ways.
          </p>
          <br />

          <ol className="ml-4">
            <li>
              <strong>a.&nbsp;Unlock Paid Content:</strong>&nbsp;User can redeem
              points to gain access to our premium paid content including
              workshop(s) and podcast(s)
            </li>
            <li>
              <strong>b.&nbsp;</strong>Join the{" "}
              <strong>'Informed Community'</strong>. Users can enter our
              esteemed
              <strong>Informed Community</strong> and unlock a pre-specified set
              of privileges.
            </li>
          </ol>

          <br />
          <p>
            Furthermore, the User is hereby informed that the aforementioned
            accrued points shall be subject to a maximum limit and shall be
            subject to expiration on a specified date.
          </p>
          <br />
          <p>
            Customer Solicitation: Unless you explicitly inform our third-party
            business partner(s), representative(s) or direct Equentis Edutech or
            Equentis Edutech - Informed InvestoRR sales representative(s) during
            their calls of your wish to opt out from further direct company
            communications and solicitations, you are consenting to receive
            additional emails and call solicitations from Equentis Edutech, its
            affiliates, subsidiaries, holding and group companies and its
            designated in-house or third party call team(s), if any, even if you
            are listed on the national DND list or any similar lists as
            available.
          </p>
          <br />
          <p>
            Opt Out Procedure: We provide 3 easy ways to opt out from future
            solicitations.
          </p>
          <br />
          <ul>
            <li>
              You may use the opt-out link found in any email solicitation that
              you may receive.
            </li>
            <li>
              You may also choose to opt out, via sending your email address to:{" "}
              <a
                className="text-primary-color underline"
                href="mailto:support.informedinvestorr@equentisedutech.com"
              >
                support.informedinvestorr@equentisedutech.com
              </a>{" "}
            </li>
            <li>
              You may send a written remove request to – “Mumbai office” –
              Marathon Futurex, A-703, 7 th Floor, Mafatlal Mills Compound, N M
              Joshi Marg, Lower Parel East, Mumbai 400013
            </li>
          </ul>
          <br />
          <p>
            Rights: The copyright in the video/content belongs exclusively to
            Equentis Edutech. Equentis Edutech holds proprietary rights and
            trade secrets in the services. The Video/content shall only be
            views/referred by those persons to whom it has been delivered. You
            are prohibited from copying, reproducing, reselling or
            redistributing any services as distributed by Equentis Edutech.
            Equentis Edutech retain the rights to all intellectual property
            rights including but not limited to copyrights, trademarks, trade
            secrets, patents, moral rights, and licensing rights.. No printing,
            reproduction, copying, distribution of the video/content in any
            manner whatsoever, in whole or in part, is permitted, without the
            prior express written consent of Equentis Edutech.
          </p>
        </div>
      ),
    },
    {
      thumb:
        "https://equentis-web.s3.ap-south-1.amazonaws.com/RNR_old/terms/gen_02.png",
      title: "Platform",
      content: (
        <div class="panel-body">
          <p>
            <strong>
              Content, Intellectual Property &amp; Third Party Links:
            </strong>
            &nbsp;Links: In addition to making Services available, this Platform
            also offers other information. Equentis Edutech may not always
            create the information or services offered on this Platform; instead
            the information may be gathered from other sources. To the extent
            that Equentis Edutech does create the content on this Platform, such
            content is protected by intellectual property laws of the India,
            foreign nations, and international bodies. Unauthorized use of the
            material/information may constitute a violation of copyright,
            trademark, and/or other laws. You acknowledge that your use of the
            content on this Platform is for personal, non-commercial purposes.
            Any links to third-party website(s) or application(s), if provided,
            are offered solely for your convenience. Equentis Edutech does not
            endorse the contents of any such third-party website(s) or
            application(s). Equentis Edutech bears no responsibility for the
            content or any potential damage resulting from your access to or
            reliance on these third-party website(s) or application(s). If you
            choose to link to a third-party website(s) or application(s), you do
            so at your own risk.
          </p>
          <br />
          <p>
            This Video/Content is prepared and distributed by Equentis Edutech
            for information and Education purpose only. Any references to any
            stocks, companies, scrips, or securities used in the educational
            course(s), workshop(s) and podcast(s) are solely for illustration,
            example and educational purposes only and shall not be deemed
            considered or construed to be an advice or recommendation for
            purchase or sale of those stocks, companies, scrips, or securities.
            It is hereby declared that the details of the company (ies) featured
            in any video is neither represent the views of the Subscriber nor
            those of the Equentis Edutech nor its holding(s)/subsidiary
            (ies)/Associate(s)/Affiliate(s). Furthermore, neither the Subscriber
            nor any person(s) associated with Equentis Edutech or its
            holding(s)/subsidiary (ies)/Associate(s)/Affiliate(s) have received
            any compensation from the mentioned company (ies) in the past twelve
            months.
          </p>
          <br />

          <p>
            Course(s), Workshop(s) and podcast(s) content for all our services
            is provided only for the purpose of guidance and education. Our
            course content is reviewed and is subject to change without notice.
            Equentis Edutech reserves the right to modify training/course
            content without prior notice.
          </p>
          <br />

          <p>
            Information/opinions/views are not meant to serve as a professional
            investment or financial planning guide for the readers/viewers. No
            action is solicited based upon the information provided herein.
            Viewers and listeners should rely on information/data arising out of
            their own investigations. The video/content have been prepared
            without taking account of your objectives, financial situation or
            needs. Viewers and listeners are advised to seek independent
            professional advice before executing any investments or financial
            planning decisions. The contents of the video have been prepared
            based on publicly available information, internally developed data
            and other sources believed by Equentis Edutech to be reliable.
            Equentis Edutech or its directors, employees, affiliates, or
            representatives do not hold any responsibility for, or warrant the
            accuracy, completeness, adequacy, and reliability of such
            information / opinions / views. While due care has been taken to
            ensure that the disclosures and opinions given are fair and
            reasonable, none of the directors, employees, affiliates, or
            representatives of Equentis Edutech shall be liable for any direct,
            indirect, special, incidental, consequential, punitive, or exemplary
            damages, including lost profits arising in any way whatsoever from
            the information / opinions / views contained in this Video/Content.
          </p>
          <br />
          <p>
            <strong>License:&nbsp;</strong>By accessing and using this Platform,
            you are granted a limited, non-exclusive, non-transferable right to
            access the course, workshop(s) and podcast(s) on the Platform in
            connection with your normal, non-commercial, use of the Platform.
            You agree that you shall not copy, reproduce, transmit, distribute,
            or create derivative works of such content or information without
            express written authorization from Equentis Edutech or the
            applicable third party (if third party content is at issue). This
            limited license does not grant you any ownership rights to the
            content or materials on the Platform. Any unauthorized use of the
            content or materials may result in legal action being taken against
            you.
          </p>
          <p>
            <strong>Posting:&nbsp;</strong>By posting, storing, or transmitting
            any content on the Platform, you hereby grant Equentis Edutech, a
            perpetual, worldwide, non-exclusive, royalty-free, assignable, right
            and license to use, copy, display, perform, create derivative works
            from, distribute, have distributed, transmit and assign such content
            in any form, in all media now known or hereinafter created, anywhere
            in the world. Equentis Edutech does not have the ability to control
            the nature of the user-generated content offered through the
            Platform. You are solely responsible for your interactions with
            other users of the Platform and any content you post. Equentis
            Edutech is not liable for any damage or harm resulting from any
            posts by or interactions between users. Equentis Edutech reserves
            the right, but has no obligation, to monitor interactions between
            and among users of the Platform.
          </p>
        </div>
      ),
    },
    {
      thumb:
        "https://equentis-web.s3.ap-south-1.amazonaws.com/RNR_old/terms/gen_03.png",
      title: "Disclaimer",
      content: (
        <div class="panel-body">
          <p>
            YOU EXPRESSLY UNDERSTAND AND AGREE THAT, TO THE MAXIMUM EXTENT
            PERMITTED BY APPLICABLE LAW, this site, the services and other
            information are provided “AS-IS” basis, without warranty of any
            kind, express, implied, statutory or otherwise, including the
            implied warranties of title, non-infringement, merchantability or
            fitness for a particular purpose. We disclaim all responsibility for
            your ability or inability, as well as any associated costs or fees,
            to access{" "}
            <a
              className="text-primary-color underline"
              href="https://www.equentisedutech.com/"
            >
              https://www.equentisedutech.com/
            </a>{" "}
            Additionally, we do not assume any liability for the failure to
            store or maintain any user communications or personal settings.
            <br />
            PROVIDED that the User agrees, accepts and understands that all
            services related work is carried out on a good-faith basis and as
            per specific understanding / agreement by and between us and the
            party concerned.
          </p>
          <br />
          <p>The Platform makes no warranty that:</p>
          <br />
          <ul>
            <li>
              Your requirements will be met or that services provided will be
              uninterrupted, timely, secure or error-free;
            </li>

            <li>
              Information obtained and descriptions of services will be
              effective, accurate or reliable;
            </li>

            <li>
              any errors or defects in the Platform, services or other materials
              will be corrected;
            </li>

            <li>
              The services availed shall be available throughout the year, or
              available at all times; or your concerns will be addressed by us
              to your satisfaction.
            </li>
          </ul>
          <br />
          <p>
            No advice or information, whether oral or written, obtained by you
            from Equentis Edutech or from its services shall create any warranty
            not expressly stated in these terms and conditions.
            <br />
            The user understands and agrees that the Platform{" "}
            <a
              className="text-primary-color underline"
              href="https://www.equentisedutech.com/"
            >
              https://www.equentisedutech.com/
            </a>{" "}
            does not offer any financial or legal advice through the site or
            application. The information – including Articles written in blog,
            contents, video courses, workshops and podcasts provided on the site
            are solely for individual education and understanding the financial
            and investment issues involved and help you make sensible financial
            decisions yourself.{" "}
            <a
              className="text-primary-color underline"
              href="https://www.equentisedutech.com/"
            >
              https://www.equentisedutech.com/
            </a>{" "}
            does not manage your funds nor advises or directs you to acquire,
            dispose of or retain any securities. This course(s), workshop(s) and
            podcast(s) on this Platform is only for educational purposes.
            <br />
            The course(s), workshop(s) and podcast(s) on the Platform is
            prepared and distributed by Equentis Edutech for Education purposes
            only. Any reference made via Illustration, examples, or explanation
            is solely for clarification/understanding purposes and shall not be
            deemed or construed to be advice for the purpose of purchase or sale
            of any security, scrips, stocks, derivatives or any other security
            in the market.
          </p>
        </div>
      ),
    },
    {
      thumb:
        "https://equentis-web.s3.ap-south-1.amazonaws.com/RNR_old/terms/gen_04.png",
      title: "Confidentiality",
      content: (
        <div class="panel-body">
          <p>
            You acknowledge that the Platform may contain information that is
            designated confidential by us and that you shall not disclose such
            information without our prior written consent. Your information is
            regarded as confidential and therefore will not be divulged to any
            third party except as set forth in this and any other term of use
            applicable to our Platform.
          </p>
        </div>
      ),
    },
    {
      thumb:
        "https://equentis-web.s3.ap-south-1.amazonaws.com/RNR_old/terms/gen_05.png",
      title: "Conflict of Interest",
      content: (
        <div class="panel-body">
          <p>
            Equentis Edutech Limited, is a wholly owned subsidiary of Equentis
            Wealth Advisory Services Limited, who is an SEBI registered
            Investment Adviser having registration No.  INA000003874. Subscriber
            understands that Equentis Wealth Advisory Services Limited serve as
            an Investment Adviser for various client(s) who had registered under
            the Investment Advisory Services of Equentis Wealth Advisory
            Services Limited and Equentis Wealth Advisory Services Limited will
            continue to do so.
          </p>
          <br />
          <p>
            Users understand that Equentis Wealth Advisory Services Limited may
            give advice or take action in performing their duties to other
            client, or for their own accounts, that differ from the subscription
            to educational content given/ subscribed to or acts taken for the
            Client(s), subject to meeting all applicable regulations.
          </p>
          <br />
          <p>
            User understand that the course(s), workshop(s) and podcast(s) on
            the Platform is separate (Informed InvestoRR) segment/business from
            that of Investment Advisory segment/business of Equentis Wealth
            Advisory Services Limited, which is provided by its wholly owned
            subsidiary Equentis Edutech Limited. Also the
            Client(s)/Subscriber(s)/User(s) of both the segment/business whether
            common or not; shall be treated as two different/separate
            client(s)/subscriber(s)/User(s).
          </p>
        </div>
      ),
    },
    {
      thumb:
        "https://equentis-web.s3.ap-south-1.amazonaws.com/RNR_old/terms/gen_06.png",
      title: "Limitation of Liability",
      content: (
        <div class="panel-body">
          <p>
            Equentis Edutech entire liability, and your exclusive remedy, in
            law, in equity, or otherwise, with respect to the Platform content
            and services and/or for any breach of this agreement is solely
            limited to the amount you paid, less shipping and handling, for
            Services purchased via the Platform.
          </p>
          <br />
          <p>
            Equentis Edutech will not be liable for any direct, indirect,
            incidental, special or consequential damages in connection with this
            agreement or the services in any manner, including liabilities
            resulting from (1) the use or the inability to use the Platform
            content or services; (2) the cost of procuring substitute services
            or content; (3) any services purchased or obtained or transactions
            entered into through the Platform; or (4) any lost profits you
            allege.
          </p>
          <br />
          <p>
            Some jurisdictions do not allow the limitation or exclusion of
            liability for incidental or consequential damages, so some of the
            above limitations may not apply in that case.
          </p>
        </div>
      ),
    },
    {
      thumb:
        "https://equentis-web.s3.ap-south-1.amazonaws.com/RNR_old/terms/gen_07.png",
      title: "Indemnification",
      content: (
        <div class="panel-body">
          <p>
            You will release, indemnify, defend and hold harmless Equentis
            Edutech and any of its contractors, agents, employees, officers,
            directors, shareholders, subsidiaries, holding, associates,
            affiliates and assigns from all liabilities, claims, damages, costs
            and expenses, including reasonable attorneys’ fees and expenses, of
            third parties relating to or arising out of (1) this Agreement or
            the breach of your warranties, representations and obligations under
            this Agreement; (2) the Platform content or your use of the Platform
            content; (3) the services or your use of the services (including
            free services); (4) any intellectual property or other proprietary
            right of any person or entity; (5) your violation of any provision
            of this Agreement; or (6) any information or data you supplied to
            Equentis Edutech. When Equentis Edutech is threatened with suit or
            sued by a third party, Equentis Edutech may seek written assurances
            from you concerning your promise to indemnify Equentis Edutech; your
            failure to provide such assurances may be considered by Equentis
            Edutech to be a material breach of this Agreement. Equentis Edutech
            will have the right to participate in any defence by you of a
            third-party claim related to your use of any of the Platform content
            or services, with counsel of Equentis Edutech’s choice at its
            expense. The terms of this provision will survive any termination or
            cancellation of this Agreement or your use of the Platform or
            services.
          </p>
        </div>
      ),
    },
    {
      thumb:
        "https://equentis-web.s3.ap-south-1.amazonaws.com/RNR_old/terms/gen_08.png",
      title: "Privacy",
      content: (
        <div class="panel-body">
          <p>
            Equentis Edutech believes strongly in protecting user information.
            Please refer to Equentis Edutech’s privacy policy, incorporated by
            reference herein, and posted on the Platform.
          </p>
        </div>
      ),
    },
    {
      thumb:
        "https://equentis-web.s3.ap-south-1.amazonaws.com/RNR_old/terms/gen_09.png",
      title: "Agreement to Bind",
      content: (
        <div class="panel-body">
          <p>
            By using this Platform or registering on the Platform or subscribing
            to any services, you acknowledge that you have read and agree to be
            bound by this agreement and all terms and conditions on this
            Platform.
          </p>
        </div>
      ),
    },
    {
      thumb:
        "https://equentis-web.s3.ap-south-1.amazonaws.com/RNR_old/terms/gen_10.png",
      title: "General",
      content: (
        <div class="panel-body">
          <li>
            <strong>Force Majeure:</strong>&nbsp;Equentis Edutech will not be
            deemed in default hereunder or held responsible for any cessation,
            interruption or delay in the performance of its obligations
            hereunder due to earthquake, flood, fire, storm, natural disaster,
            act of God, war, terrorism, armed conflict, labor strike, lockout,
            boycott or any other act which is beyond the control of Equentis
            Edutech.
          </li>
          <br />
          <li>
            <strong>Cessation of Operation:</strong>&nbsp;Equentis Edutech may
            at any time, in its sole discretion and without advance notice to
            you, cease operation of the Platform and distribution of the
            Services.
          </li>
          <br />
          <li>
            <strong>Entire Agreement:</strong>&nbsp;This Agreement comprises the
            entire agreement between you and Equentis Edutech and supersedes all
            the prior agreements, if any pertaining to the subject matter
            contained herein.
          </li>
          <br />
          <li>
            <strong>Effect of Waiver:</strong>&nbsp;The failure of Equentis
            Edutech to exercise or enforce any right or provision of this
            Agreement will not constitute a waiver of such right or provision.
            If any provision of this Agreement is found by a court of competent
            jurisdiction to be invalid, the parties nevertheless agree that the
            court should endeavor to give effect to the parties’ intentions as
            reflected in the provision, and the other provisions of this
            Agreement remain in full force and effect.
          </li>
          <br />
          <li>
            <strong>Governing Law; Jurisdiction:</strong>&nbsp;The Platform
            originates from Mumbai, Maharashtra. This Agreement will be governed
            by the laws of the State of Maharashtra without regard to its
            conflict of law principles to the contrary. Neither you nor Equentis
            Edutech will commence or prosecute any suit, proceeding or claim to
            enforce the provisions of this Agreement, to recover damages for
            breach of or default of this Agreement, or otherwise arising under
            or by reason of this Agreement, other than in courts located in
            Mumbai, Maharashtra. By using this Platform or subscribing to any
            Services, you consent to the jurisdiction and venue of such courts
            in connection with any action, suit, proceeding or claim arising
            under or by reason of this Agreement.
          </li>
          <br />
          <li>
            <strong>Waiver of Class Action Rights:&nbsp;</strong>By entering
            into this agreement, you hereby irrevocably waive any right you may
            have to join claims with those of other in the form of a class
            action or similar procedural device. Any claims arising out of,
            relating to, or connection with this agreement must be asserted
            individually.
          </li>
          <br />
          <li>
            <strong>Termination:</strong>&nbsp;Equentis Edutech reserves the
            right to terminate your access to the Platform if it reasonably
            believes, in its sole discretion, that you have breached any of the
            terms and conditions of this Agreement. Following termination, you
            will not be permitted to use the Platform and Equentis Edutech may,
            in its sole discretion and without advance notice to you, cancel all
            your subscription of services. If your access to the Platform is
            terminated, Equentis Edutech reserves the right to exercise whatever
            means it deems necessary to prevent unauthorized access of the
            Platform. This Agreement will survive indefinitely unless and until
            Equentis Edutech chooses, in its sole discretion and without advance
            to you, to terminate it.
          </li>
          <br />
          <li>
            <strong>Domestic Use:</strong>&nbsp;Equentis Edutech makes no
            representation that the Platform or services are appropriate or
            available for use in locations outside India. Users who access the
            Platform from outside India do so at their own risk and initiative
            and must bear all responsibility for compliance with any applicable
            local laws.
          </li>
          <br />
          <li>
            <strong>Assignment:</strong>&nbsp;You may not assign your rights and
            obligations under this Agreement to anyone. Equentis Edutech may
            assign its rights and obligations under this Agreement in its sole
            discretion and without advance notice to you.
          </li>
          <br />
          <p className="uppercase">
            BY USING THIS PLATFORM OR SUBSCRIBING TO THE SERVICES FROM THIS
            PLATFORM YOU AGREE TO BE BOUND BY ALL OF THE TERMS AND CONDITIONS OF
            THIS AGREEMENT.
          </p>
        </div>
      ),
    },
    {
      thumb:
        "https://equentis-web.s3.ap-south-1.amazonaws.com/RNR_old/terms/gen_12.png",
      title: "Governing Law and Jurisdiction Clause",
      content: (
        <div class="panel-body">
          <p>
            This Agreement will be governed by the Laws of India and any
            disputes arising herein shall be subject to the exclusive
            jurisdiction of the Courts of Mumbai.
          </p>
        </div>
      ),
    },
    {
      thumb:
        "https://equentis-web.s3.ap-south-1.amazonaws.com/RNR_old/terms/gen_13.png",
      title: "Terms",
      content: (
        <div class="panel-body">
          <p>
            The Terms of any paid course/subscription shall be as per the
            subscribed product description mentioned on the Platform.
          </p>
        </div>
      ),
    },
    {
      thumb:
        "https://equentis-web.s3.ap-south-1.amazonaws.com/RNR_old/terms/gen_14.png",
      title: "Refund Policy",
      content: (
        <div class="panel-body">
          <p>
            We strictly adhere to a{" "}
            <strong>&quot;No Refund Policy&quot;</strong> as we firmly uphold
            the quality and content of our course(s), workshop(s), and
            podcast(s). To facilitate an informed decision- making process, we
            offer access to various free course(s), workshop(s), and podcast(s)
            for preview before enrolling or subscribing to our paid offerings.
            Subsequent to these previews, you may elect to subscribe to our paid
            course(s), workshop(s) and podcast(s). Please note that all payments
            made for subscription to any paid course(s), workshop(s), or
            podcast(s) are non-refundable, non-reversible, and non-transferable
          </p>
        </div>
      ),
    },
    {
      thumb:
        "https://equentis-web.s3.ap-south-1.amazonaws.com/RNR_old/terms/gen_15.png",
      title: "Account Deletion",
      content: (
        <div class="panel-body">
          <p>
            When your account is deleted, you will receive a confirmation email
            acknowledging the deletion. If you decide to restore your account
            after deletion, you may do so by sending an email to{" "}
            <a
              className="text-primary-color underline"
              href="mailto:support.informedinvestorr@equentis.com"
            >
              support.informedinvestorr@equentis.com
            </a>{" "}
            within 7 days of deletion. Your account reactivation will be
            processed within the following 72 hours. Please note that refunds
            are not provided under any circumstances.
          </p>
        </div>
      ),
    },
    {
      thumb:
        "https://equentis-web.s3.ap-south-1.amazonaws.com/RNR_old/terms/gen_16.png",
      title: "Payments and Taxes",
      content: (
        <div class="panel-body">
          <p>
            <strong>ALL SUBSCRIPTION TO THE SERVICES ARE FINAL.</strong>
          </p>
          <br />
          <p>
            Before subscribing to any paid course(s), workshop(s) and
            podcast(s), the user shall make the necessary payments of
            subscription fee.
          </p>
          <p>
            For enjoying uninterrupted services, the user shall require to make
            the timely payment of the subscription / renewal fee.
          </p>
          <p>
            Your total price will include the subscription fees plus the
            applicable tax; such tax is based on the tax rate in effect at the
            time you subscribe to the product.
          </p>
          <p>
            Subscription fee for services offered on the Platform may change at
            any time and we do not provide fee protection or refunds in the
            event of a fee reduction or promotional offering.
          </p>
        </div>
      ),
    },
    {
      thumb:
        "https://equentis-web.s3.ap-south-1.amazonaws.com/RNR_old/terms/gen_17.png",
      title: "E-Mandate",
      content: (
        <div class="panel-body">
          <li>
            <strong>What is an eMandate?</strong>
          </li>
          <p className="mx-5">
            e-Mandate is a payment service initiated by RBI and the National
            Payments Corporation of India (NPCI). A mandate is a standard
            instruction to automatically debit the instructed amount from your
            bank account. The mandate is also known as NACH (NACH stands for
            National Automated Clearing House).
          </p>
          <br />
          <li>
            <strong>My eMandate setup failed. How should I set it up?</strong>
          </li>
          <p className="mx-5">
            eMandate registration could fail due to multiple reasons like
            authentication failure, incorrect login details, premature closure
            of registration screen etc.
          </p>
          <br />
          <p className="mx-5">
            In such cases, verify all your details and retry. If you are still
            not able to register successfully, get in touch with us on our email
            at _______________________________________________ and we will guide
            you through the process.
          </p>
          <br />
          <li>
            <strong>How do I cancel my eMandate?</strong>
          </li>
          <p className="mx-5">
            You can disable auto-pay any time to stop the payment using your
            banking account. If you want to cancel your mandate, you can do it
            by yourself using our feature
            <strong>&quot;Cancel eMandate&quot;</strong> in your assigned
            dashboard. You can also get in touch with our support team
            at ____________________________________________ and we will guide
            you through the process.
          </p>
        </div>
      ),
    },
  ],
};
