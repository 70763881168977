import { data } from "../Data";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const storeRedirect = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Check if Android
  if (/android/i.test(userAgent)) {
    window.location.href = data.google_play_store; // Google Play link
  }
  // Check if iOS
  else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    window.location.href = data.apple_store;
  } else {
    alert("Unsupported platform or unable to detect device.");
  }
};

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
